import React, { useState } from "react"
import { Link } from "gatsby"
// import PropTypes from "prop-types"
import EuroLemon from "../images/icons/white/euro-lemon.svg"
import EuroLemonBK from "../images/icons/black/euro-lemon.svg"
// import EuroLemonBlack from "../images/icons/black/euro-lemon.svg"
import PaperScroll from "./paper-scroll"
import Pipe from "./pipe"
import FlashBubble from "./flash-bubble"
import Tickets from "./tickets"
import classNames from "classnames"
import { eventDate } from "../pages/index"

const Header = ({ color }) => {
  const [navVisible, setNavVisible] = useState(false)
  const now = new Date().getTime()
  // console.log(eventDate)

  return (
    <PaperScroll
      className="header"
      sentinel={<div className="header__sentinel" />}
    >
      <header className="header__wrap">
        <div
          className={classNames(
            "header__visible flex justify-between flex-row flex-no-wrap items-center px-4 md:px-8",
            { visible: !navVisible }
          )}
        >
          <div className="header__dates w-24 md:w-32 mr-auto">
            {/* <FlashBubble color={color} /> */}
          </div>
          {/* <div className="header__tickets ml-auto">
            {eventDate.getTime() > now ? (
              <Tickets />
            ) : (
              <a
                className="link-yellow font-sans uppercase leading-tight"
                href="/#stream"
              >
                Watch
              </a>
            )}
          </div> */}
          {/* <div className="header__brand w-12 ml-8" onMouseEnter={() => setNavVisible(true)} onClick={() => setNavVisible(true)}> */}
          <div className="header__brand w-12 ml-8">
            <div>
              <Link to="/">
                <img
                  src={color === "black" ? EuroLemonBK : EuroLemon}
                  role="presentation"
                />
              </Link>
            </div>
          </div>
        </div>
        {/* <Pipe color={color} /> */}
      </header>
    </PaperScroll>
  )
}

export default Header
