import React, { Component } from 'react'
import Img from "gatsby-image"

export default class Filmmaker extends Component {
  render() {
    const { node } = this.props

    return (
      <div className="filmmaker">
        <div className="filmmaker__title text-center mt-4 mb-4 text-sm">
          {node.frontmatter.title}
        </div>
        <div
          className="filmmaker__image mb-4"
        >
          {node.frontmatter.image && (
            <Img
              className="mx-auto"
              fluid={
                node.frontmatter.image.childImageSharp.fluid
              }
            />
          )}
        </div>
        <div
          className="filmmaker__content text-left text-sm mb-4"
          dangerouslySetInnerHTML={{ __html: node.html }}
        />
      </div>
    )
  }
}
