import React, { Component } from "react"
import classNames from "classnames"

class PaperScroll extends Component {
  state = {
    visible: false,
  }

  constructor(props) {
    super(props)

    this.elRef = React.createRef()
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(this.callback, {
      root: null,
      rootMargin: "0px",
      threshold: [0],
    })

    this.observer.observe(this.elRef.current)
  }

  componentWillUnmount() {
    this.observer.unobserve(this.elRef.current)
    this.observer.disconnect()
  }

  callback = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.setState({ visible: true })
      } else {
        this.setState({ visible: false })
      }
    })
  }

  render() {
    const { sentinel, children, className } = this.props
    const { visible } = this.state

    return (
      <div
        className={classNames(className, "paper-scroll", {
          visible,
        })}
      >
        {React.cloneElement(sentinel, { ref: this.elRef })}
        {children}
      </div>
    )
  }
}

export default PaperScroll
