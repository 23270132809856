import React, { Component } from 'react'
import DrawnHeading from './drawn-heading';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import HostsModals from './hosts-modals';

export default class Hosts extends Component {
  render() {
    const { color } = this.props
    return (
      <StaticQuery query={graphql`
      query {
        rubyWaters: file(relativePath: { eq: "titles/title-ruby-waters.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        kayIsgay: file(relativePath: { eq: "titles/title-kay-isgay.png" }) {
          childImageSharp {
            fluid(maxWidth: 465) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        spk: file(relativePath: { eq: "spk@2x.png" }) {
          childImageSharp {
            fixed(width: 350) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        rubyWatersBK: file(relativePath: { eq: "titles/title-ruby-waters-bk.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        kayIsgayBK: file(relativePath: { eq: "titles/title-kay-isgay-bk.png" }) {
          childImageSharp {
            fluid(maxWidth: 465) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        spkALT: file(relativePath: { eq: "spk-alt@2x.png" }) {
          childImageSharp {
            fixed(width: 350) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        hosts: file(relativePath: { eq: "titles/title-hosts.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        hostsBK: file(relativePath: { eq: "titles/title-hosts-bk.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }`}
        render={data => {
          const rubyWaters = (color === 'black') ? data.rubyWatersBK.childImageSharp.fluid : data.rubyWaters.childImageSharp.fluid
          const kayIsgay = (color === 'black') ? data.kayIsgayBK.childImageSharp.fluid : data.kayIsgay.childImageSharp.fluid
          const spk = (color === 'black') ? data.spkALT.childImageSharp.fixed : data.spk.childImageSharp.fixed
          const hosts = (color === 'black') ? data.hostsBK.childImageSharp.fluid : data.hosts.childImageSharp.fluid

          return (
            <div>
              <div className="block text-center my-16 px-28 md:px-0">
                <DrawnHeading text="Hosts" width="300px">
                  <Img fluid={hosts} />
                </DrawnHeading>
              </div>
              <div className="hosts my-8 text-center flex flex-col lg:flex-row flex-no-wrap w-full justify-center items-center px-2">
                <div className="hosts__icon w-full md:w-1/4 px-8 md:px-0 mr-8">
                  <Img fixed={spk} />
                </div>
                <div className="hosts__names w-full md:w-1/4 flex flex-col flex-no-wrap">
                  <HostsModals buttons={[
                    (<div className="mb-2">
                      <DrawnHeading text="Ruby Waters" width="600px">
                        <Img fluid={rubyWaters} />
                      </DrawnHeading>
                    </div>),
                    (<div className="ml-8">
                      <DrawnHeading text="Kay Isgay" width="465px">
                        <Img fluid={kayIsgay} />
                      </DrawnHeading>
                    </div>),
                  ]} />
                </div>
              </div>
            </div>
          )
        }} />
    )
  }
}
