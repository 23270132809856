import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Modal from "./modal"
import Filmmaker from './filmmaker'

export default class FilmmakersModal extends Component {
  static propTypes = {
    prop: PropTypes,
  }

  render() {
    return (
      <StaticQuery
        query={query}
        render={data => {
          const sorted = data.allMarkdownRemark.edges.sort(({ node: a }, { node: b }) => {
            if (a.frontmatter.country < b.frontmatter.country) {
              return -1
            }
            if (a.frontmatter.country > b.frontmatter.country) {
              return 1
            }
            return 0
          }).reverse()

          return (
            <div className="flex flex-row flex-wrap my-8 w-full md:w-5/6 mx-auto">
              {sorted.map(({ node }) => {
                const buttonText = <React.Fragment>
                  <div className="filmmaker__title text-sm">
                    {node.frontmatter.title}
                  </div>
                </React.Fragment>

                return (
                  <div className="w-1/2 md:w-1/4 mb-4 pr-4">
                    <Modal
                      id={node.id}
                      buttonText={buttonText}
                    >
                      <Filmmaker node={node} />
                    </Modal>
                  </div>
                )
              })}
            </div>
          )
        }}
      />
    )
  }
}

const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "filmmakers" } } }) {
      edges {
        node {
          html
          id
          frontmatter {
            title
            type
            path
            date
            country
            link
            image {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
