import React, { Component } from "react"
import Modal from "react-modal"
import Draggable from "react-draggable"

const styleOverrides = {
  overlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    pointerEvents: 'none',
    outline: 0,
  },
  content: {
    bottom: "unset",
    overflow: "visible",
    padding: 0,
    border: "none",
    borderRadius: 0,
    position: "static",
    background: "none",
    pointerEvents: "none",
    outline: 0,
  },
}

export default class DraggableModal extends Component {
  state = {
    IS_TOUCHING: false
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('touchstart', this.onFirstTouch, false);
    }
  }

  onFirstTouch = () => {
    // document.body.classList.add('user-is-touching');

    // or set some global variable
    this.setState({ IS_TOUCHING: true });

    // we only need to know once that a human touched the screen, so we can stop listening now
    window.removeEventListener('touchstart', this.onFirstTouch, false);
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('touchstart', this.onFirstTouch, false);
    }
  }

  render() {
    const content = (
      <div className="draggable-modal__content">
        <React.Fragment>{this.props.children}</React.Fragment>
      </div>
    )

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        style={styleOverrides}
        id={this.props.id}
        className="draggable-modal"
      >
        <React.Fragment>
          {!this.state.IS_TOUCHING ? <Draggable handle=".draggable-modal__content" bounds="body">{content}</Draggable> : content}
        </React.Fragment>
      </Modal>
    )
  }
}
