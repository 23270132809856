import React from 'react'

const DrawnHeading = ({ children, text, width }) => {
  return (
    <div class="drawn inline-block max-w-full" style={{
      width,
    }}>
      <h3 class="drawn__heading text inline visuallyhidden">{text}</h3>
      <span className="drawn__image">{children}</span>
    </div>
  )
}

export default DrawnHeading
