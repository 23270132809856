import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const FlashBubble = ({ color }) => {
  const data = useStaticQuery(graphql`
    query {
      bang: file(relativePath: { eq: "illustrations/dates@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      bangBK: file(relativePath: { eq: "illustrations/dates-bk@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 220) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className="flash relative max-w-full z-0" style={{ width: "220px" }}>
      <div className="flash__background z-0">
        <Img fluid={(color === 'black') ? data.bangBK.childImageSharp.fluid : data.bang.childImageSharp.fluid} />
      </div>
    </div>
  )
}

FlashBubble.propTypes = {
  html: PropTypes.element,
}

export default FlashBubble
