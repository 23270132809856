import React, { Component } from "react"
import PropTypes from "prop-types"
import DraggableModal from "./draggable-modal"
import Close from '../images/icons/white/close.svg'
export default class Modal extends Component {
  static propTypes = {
    buttonText: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }

  state = {
    open: false,
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  handleClose = () => this.setState({ open: false })

  render() {
    return (
      <React.Fragment>
        <div className="button button--discreet inline-block" onClick={this.handleClick}>
          {this.props.buttonText}
        </div>
        <DraggableModal
          isOpen={this.state.open}
          onRequestClose={this.handleClose}
          id={this.props.id}
        >
          <div className="modal__wrapper">
            <div className="modal__background" />
            <div className="modal__foreground">
              {this.props.children}
            </div>
            <button className="modal__close" onClick={this.handleClose}>
              <img alt="" src={Close} />
            </button>
          </div>
        </DraggableModal>
      </React.Fragment>
    )
  }
}
