import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Clock from "../components/clock"
import Bands from "../components/bands"
import Octo from "../components/octo"
import DrawnHeading from "../components/drawn-heading"
import Filmmakers from "../components/filmmakers"
import Hosts from "../components/hosts"
import Tickets from "../components/tickets"
import Link from "gatsby-link"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export const eventDate = new Date("may 23, 2019 19:00 GMT+01:00")
class IndexPage extends React.Component {
  render() {
    const { data } = this.props

    // console.log(data);
    return (
      <Layout color="#fff" background="#000" classNames="page page--index">
        <SEO title="Welcome" pathname={this.props.location.pathname} />
        <Banner />
        <div>
          <a
            className="index-link w-full h-screen"
            style={{ paddingBottom: "100vh" }}
            href="##"
          />
          <div id="#" className="container p-4">
            <div className="text-center w-full my-16">
              <Octo />
            </div>
            <div className="text-center font-sans uppercase">
              <p>
                <Link to="/exhibition">EuroNoize Exhibition</Link>
                <br />
                14th September — 27th October 2019
                <br />
                <br />
                <a href="http://kunsthalloslo.no/" target="_blank">
                  Kunsthall Oslo
                </a>
                <br />
                Rostockgata 2-4
                <br />
                0191 Oslo
                <br />
              </p>
            </div>
            <div className="flex justify-center my-16">
              <div className="w-full md:w-1/3 text-xs mr-4 md:mr-8">
                <div className="block mb-4">
                  <DrawnHeading text="Live Music Contest" width="600px">
                    <Img fluid={data.live.childImageSharp.fluid} />
                  </DrawnHeading>
                </div>
                <p>
                  EuroNoize is a live music event modelled on the Eurovision
                  song contest, but featuring a bizarre cast of DIY, alternative
                  and underground subpop bands.
                </p>
                <p>It was held on the 23rd of May, 2019 at Scala, London.</p>
                <p>
                  <Tickets />
                </p>
                <p className="clock font-sans text-2xs mt-4">
                  <Clock deadline={eventDate} />
                </p>
              </div>
              {/* <div className="w-full md:w-1/3 font-sans ml-4 md:ml-8 uppercase leading-tight text-sm sm:text-base">
                <p>
                  <br />
                  May 23 2019, 19:00
                  <br />
                  SCALA LONDON
                  <br />
                  275 Pentonville Rd
                  <br />
                  London N1 9NL
                  <br />
                  <br />
                  <Tickets />
                </p>
              </div> */}
            </div>
            {/* <DrawnHeading text="Participants" /> */}
            <div className="w-full " id="stream">
              <div className="mx-auto relative" style={{ maxWidth: "720px" }}>
                <div className="w-full" style={{ paddingBottom: "62.5%" }} />
                <iframe
                  id="ls_embed_1558460114"
                  src="https://livestream.com/accounts/1407411/events/8688420/player?width=797&height=450&enableInfoAndActivity=true&defaultDrawer=feed&autoPlay=true&mute=false"
                  width="720"
                  height="450"
                  frameborder="0"
                  scrolling="no"
                  allowfullscreen
                  className="absolute pin-t pin-l w-full h-full"
                />
                <script
                  type="text/javascript"
                  data-embed_id="ls_embed_1558460114"
                  src="https://livestream.com/assets/plugins/referrer_tracking.js"
                />
              </div>
            </div>
            <Bands />
            <Filmmakers />
            <Hosts />
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query {
    # rubyWaters: file(relativePath: { eq: "titles/title-ruby-waters.png" }) {
    #   childImageSharp {
    #     fixed(width: 600) {
    #       ...GatsbyImageSharpFixed_noBase64
    #     }
    #   }
    # }
    live: file(relativePath: { eq: "titles/title-live-music-contest.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
