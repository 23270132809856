import React, { Component, useState } from "react"
// import OctoSVG from '../images/octo.svg'
import DrawnHeading from "./drawn-heading"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const styles = {
  container: {
    // maxHeight: "500px",
    // width: "500px",
    // maxWidth: "100%",
    // display: 'inline-block',
  },
  trail: {
    // position: "absolute",
    // height: '500px',
    // width: '500px',
    // background: `url(${OctoSVG}) no-repeat center`,
  },
}

// var dots = [],
//     mouse = {
//       x: 0,
//       y: 0
//     };

// The Dot object used to scaffold the dots
const dot = () => {
  const { x, setX } = useState(0)
  const { y, setY } = useState(0)

  return <div className="octo__trail" style={styles.trail} ref={this.trail} />
  this.node = (function() {
    var n = document.createElement("div")
    n.className = "trail"
    document.body.appendChild(n)
    return n
  })()
}
// The Dot.prototype.draw() method sets the position of
// the object's <div> node
dot.prototype.draw = function() {
  this.node.style.left = this.x + "px"
  this.node.style.top = this.y + "px"
}

// Creates the Dot objects, populates the dots array
// for (var i = 0; i < 12; i++) {
//   var d = new dot();
//   dots.push(d);
// }

export default class Octo extends Component {
  state = {
    mouse: {
      x: 0,
      y: 0,
    },
  }

  constructor(props) {
    super(props)

    this.dots = []

    this.trail = React.createRef()
  }

  // componentDidMount() {
  //   for (var i = 0; i < 12; i++) {
  //     var d = new dot();
  //     this.dots.push(d);
  //   }

  //   this.animate()

  //   document.documentElement.addEventListener("mousemove", this.mousemove)
  // }

  // componentWillUnmount() {
  //   document.documentElement.removeEventListener("mousemove", this.mousemove)
  // }

  // mousemove = (event) => {
  //   //event.preventDefault();
  //   mouse.x = event.pageX;
  //   mouse.y = event.pageY;
  // }

  // animate = () => {
  //   this.draw();
  //   requestAnimationFrame(animate);
  // }

  // draw = () => {
  //   // Make sure the mouse position is set everytime
  //   // draw() is called.
  //   var x = mouse.x,
  //     y = mouse.y;

  //   // This loop is where all the 90s magic happens
  //   dots.forEach(function (dot, index, dots) {
  //     var nextDot = dots[index + 1] || dots[0];

  //     dot.x = x;
  //     dot.y = y;
  //     dot.draw();
  //     x += (nextDot.x - dot.x) * .6;
  //     y += (nextDot.y - dot.y) * .6;

  //   });
  // }

  render() {
    return (
      <div className="octo mb-16">
        <div className="octo__container mb-8">
          <div className="octo__wrap">
            <div className="octo__trail" ref={this.trail} />
          </div>
        </div>
      </div>
    )
  }
}
