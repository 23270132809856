/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import "../styles/styles.scss"
import Footer from "./footer"

class CSSVar extends React.Component {
  state = {
    vh: null,
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.resize);
    }
    this.resize()
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.resize);
    }
  }

  resize = () => {
    if (typeof window !== 'undefined') {
      this.setState({
        vh: window.innerHeight * 0.01
      })
    }
  }

  render() {
    const { color, background } = this.props
    const { vh } = this.state
    return (
      <style>{`
        :root {
          --text-color: ${color};
          --bg-color: ${background};
          --vh: ${vh}px;
        }
      `}</style>
    )
  }
}

const Layout = ({ children, color, background, classNames, empty }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className={classNames}>
        <CSSVar color={color} background={background} />
        <div className="wrap">
          <main id="main">
            {children}
            {!empty && <Footer />}
            {!empty && <Header color={color} />}
          </main>
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
