import React from "react"
import { Link, useStaticQuery } from "gatsby"
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import Tickets from "./tickets"

library.add(faFacebook, faInstagram, faTwitter, faEnvelope)

const FooterNav = () => {
  return (
    <nav className="footer-nav">
      <ul className="footer-nav__items nav">
        <li>
          <Link className="header__link" to="/" activeClassName="active">
            Home
          </Link>
        </li>
        <li>
          <Link to="/event" activeClassName="active">
            Event
          </Link>
        </li>
        <li>
          <Link
            className="header__link"
            to="/conference"
            activeClassName="active"
          >
            Conference
          </Link>
        </li>
        <li>
          <Link
            className="header__link"
            to="/exhibition"
            activeClassName="active"
          >
            Exhibition
          </Link>
          <a className="header__link disabled" />
        </li>
        <li>
          <a className="header__link disabled">Book</a>
        </li>
        <li>
          <Link to="/about" activeClassName="active">
            About
          </Link>
        </li>
      </ul>
    </nav>
  )
}

const FooterSocial = () => {
  return (
    <ul className="footer__social list-reset">
      <li className="inline pr-3">
        <a
          href="https://www.facebook.com/euronoize"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={["fab", "facebook"]} />
        </a>
      </li>
      <li className="inline pr-3">
        <a
          href="https://www.instagram.com/euronoize/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={["fab", "instagram"]} />
        </a>
      </li>
      <li className="inline pr-3">
        <a
          href="https://twitter.com/euronoize"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={["fab", "twitter"]} />
        </a>
      </li>
      <li className="inline">
        <a href="mailto:info@euronoize.eu">
          <FontAwesomeIcon icon={["fa", "envelope"]} />
        </a>
      </li>
    </ul>
  )
}
export default () => {
  const data = useStaticQuery(graphql`
    query {
      cercle: file(relativePath: { eq: "illustrations/cercle-jaune.png" }) {
        childImageSharp {
          fixed(width: 220) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className="footer mt-8">
      <div className="footer-container flex justify-start flex-column flex-wrap sm:flex-row sm:flex-no-wrap px-4 md:px-0">
        <div className="footer__col w-1/2 md:w-1/3">
          <FooterNav />
        </div>
        <div className="footer__col">
          <div className="font-sans leading-tight uppercase pb-2">
            <div
              className="mt-4 md:mt-0 relative flex justify-center items-center"
              style={
                {
                  // height: "15rem"
                }
              }
            >
              {/* <div className="absolute" style={{
                left: "-100px",
                right: "-100px",
                height: "100%",
                border: "1px solid red"
              }}>
                <Img fixed={data.cercle.childImageSharp.fixed} />
              </div> */}
              <p>
                EuroNoize Exhibition
                <br />
                14th September
                <br />
                — 27th October 2019
                <br />
                <a href="http://kunsthalloslo.no/" target="_blank">
                  Kunsthall Oslo
                </a>
                <br />
                Rostockgata 2-4
                <br />
                0191 Oslo
                <br />
              </p>
            </div>
            {/* <p>
              <Tickets />
            </p> */}
          </div>
          <FooterSocial />
        </div>
      </div>
      {/* <div className="footer__credits font-sans p-4 md:px-0 md:py-8">
        © {new Date().getFullYear()}
      </div> */}
    </div>
  )
}
