export const isBrowser = typeof window !== "undefined" ? true : false

export const isMobile =
  isBrowser &&
  (navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i))
    ? true
    : false

export default {
  isBrowser,
  isMobile,
}
