import React, { Component } from 'react'
import DrawnHeading from './drawn-heading';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FilmmakersModal from './filmmakers-modal';

export default class Filmmakers extends Component {
  render() {
    const { color } = this.props

    return (
      <StaticQuery query={graphql`
      query {
        filmmakers: file(relativePath: { eq: "titles/title-filmmakers.png" }) {
          childImageSharp {
            fluid(maxWidth: 580) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        filmmakersBK: file(relativePath: { eq: "titles/title-filmmakers-bk.png" }) {
          childImageSharp {
            fluid(maxWidth: 580) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }`}
        render={data => {
          const image = (color === 'black') ? data.filmmakersBK.childImageSharp.fluid : data.filmmakers.childImageSharp.fluid

          return (
            <div className="filmmakers my-8 md:px-16">
              <div className="block text-center mb-16 px-8">
                <DrawnHeading text="Filmmakers" width="580px">
                  <Img fluid={image} />
                </DrawnHeading>
              </div>
              <div className="block mb-16">
                <FilmmakersModal />
              </div>
            </div>
          )
        }} />
    )
  }
}
