import React, { Component } from "react"
import { TweenMax } from "gsap"
import EuroLemon from "../images/icons/white/pipe.svg"
import EuroLemonBK from "../images/icons/black/pipe.svg"
import Register from "../images/register.svg"
import { isMobile, isBrowser } from "../lib/utils"

const interact = (e, target) => {
  let cx = target.offsetWidth / 2
  let cy = target.offsetHeight / 2

  let x = e.clientX - target.offsetLeft
  let y = e.clientY - target.offsetTop

  TweenMax.to(target, 0.3, {
    x: x - cx,
    y: y - cy,
    // rotation: Math.floor((x - cx) / 4),
  })
}

class Pipe extends Component {
  state = {
    mobile: true,
  }

  constructor(props) {
    super(props)

    this.pipe = React.createRef()
  }

  interactionEvent = e => interact(e, this.pipe.current)

  componentDidMount() {
    if (!isMobile && isBrowser) {
      this.pipe.current.addEventListener("mousemove", this.interactionEvent)
      this.setState({ mobile: false })
    }
  }

  componentWillUnmount() {
    if (!isMobile && isBrowser) {
      this.pipe.current.removeEventListener("mousemove", this.interactionEvent)
    }
  }

  render() {
    const { color } = this.props

    // const image = (color === 'black') ? EuroLemonBK : EuroLemon
    const image = Register

    return (
      <div
        className={`pipe ${
          !this.state.mobile ? "pipe--desktop" : "pipe--mobile"
          }`}
        ref={this.pipe}
      >
        <a href="sms:+447723452211"><img src={image} alt="" /></a>
      </div>
    )
  }
}

export default Pipe
