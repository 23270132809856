import React, { Component } from 'react'
import MusiciansModal from "./musicians-modal"
import DrawnHeading from './drawn-heading';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default class Bands extends Component {
  render() {
    const { color } = this.props

    return (
      <StaticQuery query={graphql`
      query {
        bands: file(relativePath: { eq: "titles/title-bands.png" }) {
          childImageSharp {
            fluid(maxWidth: 280) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        bandsBK: file(relativePath: { eq: "titles/title-bands-bk.png" }) {
          childImageSharp {
            fluid(maxWidth: 280) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }`}
        render={data => {
          const image = (color === 'black') ? data.bandsBK.childImageSharp.fluid : data.bands.childImageSharp.fluid

          return (<div className="mb-8">
            <div className="block text-center my-16 px-28 md:px-0">
              <DrawnHeading text="Bands" width="280px">
                <Img fluid={image} />
              </DrawnHeading>
            </div>
            <div className="block mb-16">
              <MusiciansModal />
            </div>
          </div>
          )
        }} />
    )
  }
}
