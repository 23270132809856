import React from "react"
import { Link } from "gatsby"

// export default function Tickets() {
//   return (
//     <a
//       className="link-yellow font-sans uppercase leading-tight"
//       href="https://link.dice.fm/VSne80uXPU"
//       target="_blank"
//       rel="noopener noreferrer"
//     >Tickets</a>
//   )
// }

export default function Tickets() {
  return (
    <Link className="link-yellow font-sans uppercase leading-tight" to="/r">
      Results
    </Link>
  )
}
