import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EuronoizeOfficial from '../images/euronoize-official.svg'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Banner = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "euronoize-official@2x.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 4600, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <div className="banner bg-cover" style={{
        backgroundImage: `url(${EuronoizeOfficial})`,
      }} />
    )}
  />
)
export default Banner
