import React, { Component } from "react"
import PropTypes from "prop-types"
import { isBrowser } from "../lib/utils"

// TODO : expired state

export default class clock extends Component {
  static propTypes = {
    deadline: PropTypes.instanceOf(Date).isRequired,
  }

  state = {
    now: new Date().getTime(),
  }

  componentDidMount() {
    if (isBrowser) {
      this.interval = setInterval(this.tick, 500)
    }
  }

  componentWillUnmount() {
    if (isBrowser) {
      clearInterval(this.interval)
    }
  }

  tick = () => {
    this.setState({
      now: new Date().getTime(),
    })
  }

  calc = t => {
    return {
      days: Math.floor(t / (1000 * 60 * 60 * 24)),
      hours: Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((t % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((t % (1000 * 60)) / 1000),
    }
  }

  render() {
    const t = this.calc(this.props.deadline.getTime() - this.state.now)

    const pastDeadline = (this.props.deadline.getTime() < this.state.now)

    // <ul className="list-reset">
    //   <li>{t.days} days</li>
    //   <li>{t.hours} hours</li>
    //   <li>{t.minutes} minutes</li>
    //   <li>{t.seconds} seconds</li>
    // </ul>

    if (pastDeadline) {
      return null
    }

    return (
      <span>
        happening in<br />
        {t.days}&nbsp;days {t.hours}&nbsp;hours {t.minutes}&nbsp;minutes {t.seconds}&nbsp;seconds
      </span>
    )
  }
}
