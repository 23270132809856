import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Modal from "./modal"
import Host from "./host";

export default class HostsModals extends Component {
  static propTypes = {
    prop: PropTypes,
  }

  render() {
    const { buttons } = this.props

    return (
      <StaticQuery
        query={query}
        render={data => {
          let sorted = data.allMarkdownRemark.edges.sort(({ node: a }, { node: b }) => {
            if (a.frontmatter.country < b.frontmatter.country) {
              return -1
            }
            if (a.frontmatter.country > b.frontmatter.country) {
              return 1
            }
            return 0
          })

          sorted = sorted.reverse()

          return (
            <React.Fragment>
              {sorted.map(({ node }, i) => {
                const buttonText = buttons[i]

                return (
                  <Modal
                    id={node.id}
                    buttonText={buttonText}
                  >
                    <Host node={node} />
                  </Modal>
                )
              })}
            </React.Fragment>
          )
        }}
      />
    )
  }
}

const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "hosts" } } }) {
      edges {
        node {
          html
          id
          frontmatter {
            title
            type
            image {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
